<template>
  <div class="row justify-content-center">
    <div class="col-12">
      <div class="card card-custom">
        <div class="card-body p-0">
          <!--begin: Wizard-->
          <div
            class="wizard wizard-1"
            id="kt_wizard_v1"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin: Wizard Body-->
            <div
              class="row justify-content-center my-10 px-8 my-lg-10 px-lg-8"
            >
              <div class="col-xl-12">
                <!--begin: Wizard Form-->
                <form class="form" @submit.stop.prevent="formOnsubmit()">
                  <!--begin: Wizard Step 1-->
                  <div
                    class="pb-5"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                  >
                    <h5
                      class="
                        font-weight-bolder
                        text-dark
                        font-size-h4 font-size-h3-lg
                      "
                    >
                      Tambah Mata Pelajaran
                    </h5>
                    <div class="row mt-4">
                      <div class="col-md-4">
                        <b-form-group id="input-group-photo">
                  <div class="col-lg-9 col-xl-6 pl-0 pt-0">
                    <div
                      class="image-input image-input-outline"
                      id="kt_profile_avatar"
                    >
                      <div class="image-input-wrapper">
                        <img :src="photo" alt="" />
                      </div>
                      <label
                        class="
                          btn
                          btn-xs
                          btn-icon
                          btn-circle
                          btn-white
                          btn-hover-text-primary
                          btn-shadow
                        "
                        data-action="change"
                        data-toggle="tooltip"
                        title=""
                        data-original-title="Change avatar"
                      >
                        <i class="fa fa-pen icon-sm text-muted"></i>
                        <input
                          type="file"
                          name="profile_avatar"
                          accept=".png, .jpg, .jpeg"
                          @change="onFileChange($event)"
                        />
                        <input type="hidden" name="profile_avatar_remove" />
                      </label>
                      <span
                        class="
                          btn
                          btn-xs
                          btn-icon
                          btn-circle
                          btn-white
                          btn-hover-text-primary
                          btn-shadow
                        "
                        data-action="cancel"
                        data-toggle="tooltip"
                        title="Cancel avatar"
                      >
                        <i class="ki ki-bold-close icon-xs text-muted"></i>
                      </span>
                      <span
                        class="
                          btn
                          btn-xs
                          btn-icon
                          btn-circle
                          btn-white
                          btn-hover-text-primary
                          btn-shadow
                        "
                        data-action="remove"
                        data-toggle="tooltip"
                        title="Remove avatar"
                        @click="current_photo = null"
                      >
                        <i class="ki ki-bold-close icon-xs text-muted"></i>
                      </span>
                    </div>
                  </div>
                </b-form-group>
                      </div>
                      <div class="col-md">
                        <b-form-group
                      id="input-group-name"
                      label="Nama:"
                      label-for="input-name"
                    >
                      <b-form-input
                        id="input-name"
                        v-model="form.name"
                        placeholder="Nama Mata Pelajaran"
                      ></b-form-input>
                      <small class="text-danger">{{ error.name }}</small>
                    </b-form-group>

                    <b-form-group
                      id="input-group-grade"
                      label="Tingkat:"
                      label-for="input-grade"
                    >
                      <treeselect
                        v-model="form.grade_id"
                        :multiple="false"
                        placeholder="Pilih Tingkat"
                        :options="grades"
                      />
                      <small class="text-danger">{{ error.grade_id }}</small>
                    </b-form-group>

                    <b-form-group
                      id="input-group-category"
                      label="Kategori:"
                      label-for="input-category"
                    >
                      <treeselect
                        v-model="form.category_id"
                        :multiple="false"
                        placeholder="Pilih Kategori"
                        :options="categories"
                      />
                      <small class="text-danger">{{ error.category_id }}</small>
                    </b-form-group>

                    <b-form-group
                      id="input-group-collection"
                      label="Koleksi:"
                      label-for="input-collection"
                    >
                      <treeselect
                        v-model="form.collection_id"
                        :multiple="false"
                        placeholder="Pilih Koleksi"
                        :options="collections"
                      />
                      <small class="text-danger">{{
                        error.collection_id
                      }}</small>
                    </b-form-group>

                    <!-- <b-form-group
                      id="input-group-kkm"
                      label="Kriteria Ketuntasan Minimal (KKM):"
                      label-for="input-kkm"
                    >
                      <b-form-input
                        id="input-kkm"
                        v-model="form.kkm"
                        placeholder="Kriteria Ketuntasan Minimal (KKM) Mata Pelajaran"
                      ></b-form-input>
                      <small class="text-danger">{{ error.kkm }}</small>
                    </b-form-group> -->

                    <b-form-group id="input-group-tag">
                      <label for="input-description"
                        >Tag: <em class="text-muted">opsional</em></label
                      >
                      <b-form-input
                        id="input-tag"
                        v-model="form.tag"
                        placeholder="Tag"
                      ></b-form-input>
                      <small class="text-danger">{{ error.tag }}</small>
                    </b-form-group>

                    <b-form-group id="input-group-description">
                      <label for="input-description"
                        >Deskripsi:</label
                      >
                      <b-form-textarea
                        id="input-description"
                        v-model="form.description"
                        placeholder="Deskripsi Mata Pelajaran"
                        rows="4"
                        max-rows="8"
                      ></b-form-textarea>
                      <small class="text-danger">{{ error.description }}</small>
                    </b-form-group>
                      </div>
                    </div>
                    
                  </div>
                  <!--end: Wizard Step 1-->

                  <!--begin: Wizard Actions -->
                  <div class="d-flex justify-content-between border-top pt-10">
                    <div>
                      <b-button type="submit" variant="primary"
                        >Simpan</b-button
                      >
                      <b-button
                        type="button"
                        class="ml-2"
                        variant="default"
                        @click="$router.push('/courses')"
                      >
                        Batal
                      </b-button>
                    </div>
                  </div>
                  <!--end: Wizard Actions -->
                </form>
                <!--end: Wizard Form-->
              </div>
            </div>
            <!--end: Wizard Body-->
          </div>
        </div>
        <!--end: Wizard-->
      </div>
    </div>
  </div>
</template>

<style scoped>
.image-input-wrapper {
  width: 230px !important;
  height: 230px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import Swal from "sweetalert2";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  data() {
    return {
      form: {
        name: "",
        description: "",
        category_id: "",
        collection_id: "",
        tag: "",
        image: "",
      },
      error: {
        name: "",
        description: "",
        category_id: "",
        collection_id: "",
        tag: "",
        image: "",
      },
      categories: [],
      collections: [],
      grades: [],
      // other
      current_photo: null,
      default_photo: "http://dev-elearning-api.wellmagicteam.com/storage/classrooms/classroom_t.png",
    };
  },
  methods: {
    async getGradeOption() {
      let response = await module.setTreeSelect("api/grades");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.grades = response.data;
        this.grades.unshift({
          label: "Pilih Tingkat",
          id: "",
          isDisabled: true,
        });
      }
    },
    async getCollectionOption() {
      let response = await module.setTreeSelect("api/collections");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.collections = response.data;
        this.collections.unshift({
          label: "Pilih Koleksi",
          id: "",
          isDisabled: true,
        });
      }
    },
    async getCategoryOption() {
      let response = await module.setTreeSelect("api/categories/select");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.categories = response.data;
        this.categories.unshift({
          label: "Pilih Kategori",
          id: "",
          isDisabled: true,
        });
      }
    },
    onFileChange(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        this.form.image = e.target.files[0];

        reader.onload = (event) => {
          this.current_photo = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    onRemove() {
      this.current_photo = null;
      this.form.image = "";
    },
    async formOnsubmit() {
      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value);
      }
      // Make Request
      let response = await module.submit(formData, "api/courses");
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/courses");
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Master", route: "/masters/settings" },
      { title: "Mata Pelajaran", route: "/courses" },
      { title: "Tambah Mata Pelajaran" },
    ]);

    this.getCollectionOption();
    this.getCategoryOption();
    this.getGradeOption()
  },
  computed: {
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    },
  },
};
</script>